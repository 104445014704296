import { getScrollWidth } from '@/extends/lib';
import PAGES              from './pages';

const VIEW_MODES_KEYS = {
    GRID : 'GRID',
    TABLE: 'TABLE',
};

const SORT_TYPES_KEYS = {
    DATE    : 'DATE',
    NAME    : 'NAME',
    LOCATION: 'LOCATION',
};

const GROUP_TYPES_KEYS = {
    TAG     : 'TAG',
    LOCATION: 'LOCATION',
    NO_GROUP: 'NO_GROUP',
};

const ADMIN_TABS_KEYS = {
    STATS       : PAGES.ADMIN_STATS,
    USERS       : PAGES.ADMIN_USERS,
    CONFIG      : PAGES.ADMIN_CONFIG,
    LICENSE     : PAGES.LICENSE,
    SERVER_DEBUG: PAGES.SERVER_DEBUG,
};

const STREAM_INFO_TABS_KEYS = {
    INFO        : PAGES.STREAM_INFO,
    PERMISSION  : PAGES.STREAM_PERMISSION,
    SETTINGS    : PAGES.STREAM_SETTING,
    STREAM_DEBUG: PAGES.STREAM_DEBUG,
};

const USER_TABS_KEYS = {
    INFO      : PAGES.USER_INFO,
    EDIT      : PAGES.USER_EDIT,
    PERMISSION: PAGES.USER_PERMISSION,
};

const PROFILE_TABS_KEYS = {
    INFO : PAGES.PROFILE_INFO,
    EDIT : PAGES.PROFILE_EDIT,
    TOKEN: PAGES.PROFILE_TOKEN,
};

const USER_PERMISSION_TYPES = {
    ADMIN: 'ADMIN',
    USER : 'USER',
};

const STREAM_PERMISSION_TYPES = {
    ADMIN: 'admin',
    EDIT : 'edit',
    VIEW : 'view',
    NONE : 'none',
};

const UNLIMITED = 'UNLIMITED';

const ADMIN_TABS = {
    [ADMIN_TABS_KEYS.STATS]       : {
        key  : ADMIN_TABS_KEYS.STATS,
        icon : 'show_chart',
        label: '',
    },
    [ADMIN_TABS_KEYS.USERS]       : {
        key  : ADMIN_TABS_KEYS.USERS,
        icon : 'supervisor_account',
        label: '',
    },
    [ADMIN_TABS_KEYS.CONFIG]      : {
        key  : ADMIN_TABS_KEYS.CONFIG,
        icon : 'settings',
        label: '',
    },
    [ADMIN_TABS_KEYS.SERVER_DEBUG]: {
        key  : ADMIN_TABS_KEYS.SERVER_DEBUG,
        icon : 'bug_report',
        label: '',
    },
    [ADMIN_TABS_KEYS.LICENSE]     : {
        key  : ADMIN_TABS_KEYS.LICENSE,
        icon : 'card_membership',
        label: '',
    },
};

const STREAM_INFO_TABS = {
    [STREAM_INFO_TABS_KEYS.INFO]        : {
        key     : STREAM_INFO_TABS_KEYS.INFO,
        icon    : 'info',
        label   : '',
        disabled: false,
    },
    [STREAM_INFO_TABS_KEYS.PERMISSION]  : {
        key     : STREAM_INFO_TABS_KEYS.PERMISSION,
        icon    : 'vpn_key',
        label   : '',
        disabled: false,
    },
    [STREAM_INFO_TABS_KEYS.SETTINGS]    : {
        key     : STREAM_INFO_TABS_KEYS.SETTINGS,
        icon    : 'settings',
        label   : '',
        disabled: false,
    },
    [STREAM_INFO_TABS_KEYS.STREAM_DEBUG]: {
        key  : STREAM_INFO_TABS_KEYS.STREAM_DEBUG,
        icon : 'bug_report',
        label: '',
    },
};

const USER_TABS = {
    [USER_TABS_KEYS.INFO]      : {
        key     : USER_TABS_KEYS.INFO,
        icon    : 'info',
        label   : '',
        disabled: false,
    },
    [USER_TABS_KEYS.PERMISSION]: {
        key     : USER_TABS_KEYS.PERMISSION,
        icon    : 'vpn_key',
        label   : '',
        disabled: false,
    },
    [USER_TABS_KEYS.EDIT]      : {
        key     : USER_TABS_KEYS.EDIT,
        icon    : 'settings',
        label   : '',
        disabled: false,
    },
};

const PROFILE_TABS = {
    [PROFILE_TABS_KEYS.INFO] : {
        key     : PROFILE_TABS_KEYS.INFO,
        icon    : 'info',
        label   : '',
        disabled: false,
    },
    [PROFILE_TABS_KEYS.TOKEN]: {
        key     : PROFILE_TABS_KEYS.TOKEN,
        icon    : 'vpn_key',
        label   : '',
        disabled: false,
    },
    [PROFILE_TABS_KEYS.EDIT] : {
        key     : PROFILE_TABS_KEYS.EDIT,
        icon    : 'settings',
        label   : '',
        disabled: false,
    },
};

const VIEW_MODES = {
    [VIEW_MODES_KEYS.GRID] : {
        key  : 'GRID',
        class: 'grid-mode',
        icon : 'view_module',
    },
    [VIEW_MODES_KEYS.TABLE]: {
        key  : 'TABLE',
        class: 'table-mode',
        icon : 'view_list',
    },
};

const SORT_TYPES = {
    [SORT_TYPES_KEYS.DATE]    : {
        key : 'DATE',
        icon: '',
    },
    [SORT_TYPES_KEYS.NAME]    : {
        key : 'NAME',
        icon: '',
    },
    [SORT_TYPES_KEYS.LOCATION]: {
        key : 'LOCATION',
        icon: '',
    },
};

const GROUP_TYPES = {
    [GROUP_TYPES_KEYS.TAG]     : {
        key : GROUP_TYPES_KEYS.TAG,
        icon: 'label',
    },
    [GROUP_TYPES_KEYS.NO_GROUP]: {
        key : GROUP_TYPES_KEYS.NO_GROUP,
        icon: 'label_outline',
    },
};

const STREAM_STATUS = {
    RUNNING : 'running',
    PENDING : 'pending',
    PAUSED  : 'paused',
    CANCELED: 'canceled',
    STOPPED : 'stopped',
    FAILED  : 'failed',
};

const PLAYER_VIEW_MODES = {
    LIVE   : 'LIVE',
    ARCHIVE: 'ARCHIVE',
};

const USER_ACCESSES = {
    active : 'active',
    blocked: 'blocked',
};

const MOVE_TIME                 = 200;
const PANEL_SIZE                = 45;
const DEFAULT_DEPTH             = 168;
const DEFAULT_PLAYLIST_DURATION = 600;
const EN                        = 'en';
const EN_US                     = 'en-us';
const RU                        = 'ru';
const UK                        = 'uk';

const SORTING_TYPES = {
    desc   : 'desc',
    asc    : 'asc',
    nonSort: 'nonSort',
};

const LANG_MAP = {
    [EN_US]: EN,
    [EN]   : EN,
    [RU]   : RU,
    [UK]   : UK,
};

const PERMISSION_OBJECTS = {
    USER: 'USER',
    TEAM: 'TEAM',
};

const COLORS = {
    dark : '#4b4d64',
    stone: '#666',
    wolf : '#999',
};

const BREAKPOINTS_720 = 720;

const SCROLL_WIDTH = getScrollWidth();

export {
    EN,
    EN_US,
    RU,
    UK,
    LANG_MAP,
    VIEW_MODES,
    GROUP_TYPES,
    SORT_TYPES,
    MOVE_TIME,
    PANEL_SIZE,
    STREAM_STATUS,
    PLAYER_VIEW_MODES,
    DEFAULT_DEPTH,
    DEFAULT_PLAYLIST_DURATION,
    VIEW_MODES_KEYS,
    SORT_TYPES_KEYS,
    GROUP_TYPES_KEYS,
    ADMIN_TABS,
    ADMIN_TABS_KEYS,
    USER_PERMISSION_TYPES,
    UNLIMITED,
    STREAM_PERMISSION_TYPES,
    STREAM_INFO_TABS_KEYS,
    STREAM_INFO_TABS,
    PERMISSION_OBJECTS,
    USER_ACCESSES,
    COLORS,
    BREAKPOINTS_720,
    SCROLL_WIDTH,
    SORTING_TYPES,
    USER_TABS_KEYS,
    USER_TABS,
    PROFILE_TABS,
    PROFILE_TABS_KEYS,
};
