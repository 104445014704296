import {
    getDeleteToken,
    PROFILE_TOKEN_URL,
    PROFILE_TOKENS_URL,
    SYSTEM_SETTINGS_URL,
    USER_URL,
}                                 from '@/constants/api';
import { PROFILE_UPDATED_NOTICE } from '@/constants/noticeTypes';
import { USER_PERMISSION_TYPES }  from '@/constants/main';
// eslint-disable-next-line import/no-cycle
import httpClient                 from '@/config/httpClient';

/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
export default {
    namespaced: true,
    state     : {
        user  : null,
        tokens: [],

        isSavingProfile   : false,
        isChangingPassword: false,
        isFetchingProfile : false,
        isFetchingTokens  : false,
        isSavingToken     : false,
    },
    getters   : {
        userLang         : (state) => {
            let lang = null;
            if (state.user) {
                lang = state.user.language;
            }
            return lang;
        },
        isAdminProfile   : (state) => state.user && state.user.permissionType === USER_PERMISSION_TYPES.ADMIN,
        isReadOnlyProfile: (state) => state.user && (state.user.maxStreamCreation === 0 && !state.user.isAdmin),
        isManaged        : (state) => state?.user?.isManaged,
    },
    mutations : {
        setUser (state, { user }) {
            state.user = user ? {
                ...user,
                permissionType: user.isAdmin ? USER_PERMISSION_TYPES.ADMIN : USER_PERMISSION_TYPES.USER,
            } : user;
        },
        setTokens (state, { tokens }) {
            state.tokens = tokens;
        },
    },
    actions   : {
        fetchUser ({ commit, state }) {
            if (state.user) {
                return;
            }

            state.isFetchingProfile = true;

            httpClient
                .get(USER_URL)
                .then((res) => {
                    commit('setUser', { user: res.data });
                })
                .finally(() => {
                    state.isFetchingProfile = false;
                });
        },
        saveUser ({ commit, state, dispatch }, { email, login, password, fullName, oldPassword, language }) {
            state.isSavingProfile = true;

            httpClient
                .patch(USER_URL, {
                    email,
                    password,
                    oldPassword,
                    language,
                    fullName,
                    name: login,
                })
                .then(() => {
                    const adaptedUser = {
                        ...state.user,
                        isAdmin : state.user.permissionType === USER_PERMISSION_TYPES.ADMIN,
                        readOnly: Number(state.user.maxStreamCreation) === 0
                            && state.user.permissionType === USER_PERMISSION_TYPES.USER,
                    };

                    commit('setUser', {
                        user: { ...adaptedUser, name: login, email, language, fullName },
                    });
                    commit(
                        'setNotice',
                        { name: PROFILE_UPDATED_NOTICE },
                        { root: true },
                    );

                    if (password && oldPassword) {
                        dispatch('updateAuthToken', { login, password });
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        state.isSavingProfile = false;
                    }, 300);
                });
        },
        saveUserLang ({ commit, state }, { language }) {
            commit('setUser', { user: { ...state.user, language } });

            httpClient
                .patch(USER_URL, { language, email: state.user.email, name: state.user.name });
        },
        updateAuthToken ({ dispatch }, { password, login }) {
            const credentials = {
                password,
                name: login,
            };

            dispatch('auth', { credentials }, { root: true });
        },
        clearUser ({ commit }) {
            commit('setUser', { user: null });
        },
        fetchTokens ({ commit }) {
            httpClient
                .get(PROFILE_TOKENS_URL)
                .then((res) => {
                    commit('setTokens', { tokens: res.data });
                })
                .finally();
        },
        createToken ({ state, commit }, { expireTime, name }) {
            state.isSavingToken = true;

            return httpClient
                .post(PROFILE_TOKEN_URL, { expireTime, name })
                .then((res) => {
                    commit('setTokens', { tokens: [res.data, ...state.tokens] });
                    return res.data;
                })
                .finally(() => {
                    state.isSavingToken = false;
                });
        },
        deleteToken ({ state }, { tokenId }) {
            state.isDeletingToken = true;

            httpClient
                .delete(getDeleteToken(tokenId));
        },
        fetchSettings () {
            return httpClient
                .get(SYSTEM_SETTINGS_URL)
                .then((res) => {
                    return res.data;
                });
        },
    },
};
