import axios      from 'axios';
import {
    USERS_URL,
    USER_URL,
    STATS_URL,
    CONFIG_URL,
    SYSTEM_STATS_URL,
    API_VERSION_CHECK,
    SERVER_DEBUG_URL,
    getBlockUserUrl,
    getUnblockUserUrl,
    getUserPermissionsUrl,
}                 from '@/constants/api';
import {
    USER_BLOCKED_NOTICE,
    USER_SAVED_NOTICE,
    USER_UNBLOCKED_NOTICE,
}                 from '@/constants/noticeTypes';
import {
    USER_ACCESSES,
    USER_PERMISSION_TYPES,
}                 from '@/constants/main';
/* eslint-disable import/no-cycle */
import httpClient from '@/config/httpClient';

const { CancelToken } = axios;

/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
export default {
    namespaced: true,
    state     : {
        stats                 : null,
        systemStats           : null,
        config                : null,
        users                 : null,
        logs                  : null,
        currentUser           : null,
        isEditMode            : false,
        versionInfo           : null,
        currentUserPermissions: [],

        isFetchingUsers      : false,
        isSavingUser         : false,
        isFetchingUser       : false,
        isDeletingUser       : false,
        isFetchingConfig     : false,
        isFetchingStats      : false,
        isFetchingLogs       : false,
        isAddingPermission   : false,
        isFetchingPermissions: false,

        fetchingLogsTimer: null,
        cancelLogsRequest: null,
    },
    mutations : {
        setUsers (state, { users }) {
            state.users = users;
        },
        setEditMode (state, { isEditMode }) {
            state.isEditMode = isEditMode;
        },
        setCurrentUser (state, { user }) {
            state.currentUser = {
                ...user,
                permissionType: user.isAdmin ? USER_PERMISSION_TYPES.ADMIN : USER_PERMISSION_TYPES.USER,
            };
        },
        setStats (state, { stats }) {
            state.stats = stats;
        },
        setConfig (state, { config }) {
            state.config = config;
        },
        setSystemStats (state, { systemStats }) {
            state.systemStats = systemStats;
        },
        setVersionInfo (state, { versionInfo }) {
            state.versionInfo = versionInfo;
        },
        setLogs (state, { logs }) {
            state.logs = logs;
        },
        setFetchingLogsTimer (state, { timer }) {
            state.fetchingLogsTimer = timer;
        },
        clearFetchingLogsTimer (state) {
            clearTimeout(state.fetchingLogsTimer);
        },
        setCancelLogsRequest (state, { cancel }) {
            state.cancelLogsRequest = cancel;
        },
        setCurrentUserPermissions (state, { permissions }) {
            state.currentUserPermissions = permissions;
        },
        deleteCurrentUser (state) {
            state.currentUser = null;
        },
    },
    actions   : {
        fetchUsers ({ commit, state }) {
            state.isFetchingUsers = true;

            httpClient
                .get(USERS_URL, {})
                .then((res) => {
                    commit('setUsers', { users: res.data });
                })
                .finally(() => {
                    state.isFetchingUsers = false;
                });
        },
        fetchUser ({ commit, state }, { login }) {
            state.isFetchingUser = true;

            httpClient
                .get(`${USER_URL}/${login}`)
                .then((res) => {
                    commit('setCurrentUser', { user: res.data });
                })
                .finally(() => {
                    state.isFetchingUser = false;
                });
        },
        addUser ({ dispatch }, { user }) {
            dispatch('saveUser', { user, isAdding: true });
        },
        saveUser ({ commit, state }, { user, isAdding }) {
            const url          = isAdding ? USER_URL : `${USER_URL}/${user.name}`;
            state.isSavingUser = true;

            const adaptedUser = {
                ...user,
                isAdmin          : user.permissionType === USER_PERMISSION_TYPES.ADMIN,
                maxStreamCreation: user.maxStreamCreation === ''
                    ? -1 : Number(user.maxStreamCreation),
            };

            httpClient
                .post(url, adaptedUser)
                .then((res) => {
                    commit('setCurrentUser', { user: res.data });

                    commit(
                        'setNotice',
                        { name: USER_SAVED_NOTICE },
                        { root: true },
                    );

                    commit('setEditMode', { isEditMode: false });
                })
                .finally(() => {
                    state.isSavingUser = false;
                });
        },
        updateCurrentUser ({ commit, state, dispatch }, { login }) {
            let user = state.currentUser || null;

            if (state.users) {
                state.users.some((item) => {
                    if (item.name === login) {
                        user = item;
                    }

                    return item.name === login;
                });
            }

            if (user) {
                commit('setCurrentUser', { user });
            }
            else {
                dispatch('fetchUser', { login });
            }
        },
        deleteUser ({ dispatch, state }, { login }) {
            state.isDeletingUser = true;

            return httpClient
                .delete(`${USER_URL}/${login}`)
                .then(() => {
                    dispatch('fetchUsers');
                })
                .finally(() => {
                    state.isDeletingUser = false;
                });
        },
        changeUserAccess ({ commit, state }, { login, access }) {
            const url = access === USER_ACCESSES.blocked
                ? getBlockUserUrl(login) : getUnblockUserUrl(login);

            return httpClient
                .post(url, { name: login })
                .then(() => {
                    commit('setCurrentUser', {
                        user: { ...state.currentUser, state: access },
                    });

                    commit(
                        'setNotice',
                        { name: access === USER_ACCESSES.blocked ? USER_BLOCKED_NOTICE : USER_UNBLOCKED_NOTICE },
                        { root: true },
                    );
                });
        },
        clearCurrentUser ({ commit }) {
            commit('deleteCurrentUser');
            commit('setCurrentUserPermissions', { permissions: [] });
        },

        fetchStats ({ commit, state }) {
            state.isFetchingStats = true;

            httpClient
                .get(STATS_URL, {})
                .then((res) => {
                    commit('setStats', { stats: res.data });
                })
                .finally(() => {
                    state.isFetchingStats = false;
                });
        },
        fetchConfig ({ commit, state }) {
            state.isFetchingConfig = true;

            httpClient
                .get(CONFIG_URL, {})
                .then((res) => {
                    commit('setConfig', { config: res.data });
                })
                .finally(() => {
                    state.isFetchingConfig = false;
                });
        },
        fetchSystemStats ({ state, commit }) {
            state.isFetchingSystemStats = true;

            httpClient
                .get(SYSTEM_STATS_URL, {})
                .then((res) => {
                    commit('setSystemStats', { systemStats: res.data });
                })
                .finally(() => {
                    state.isFetchingSystemStats = false;
                });
        },
        fetchLogs ({ state, commit }) {
            if (state.cancelLogsRequest) {
                state.cancelLogsRequest('replaced');
            }

            httpClient
                .get(SERVER_DEBUG_URL, {
                    cancelToken: new CancelToken((cancel) => {
                        commit('setCancelLogsRequest', { cancel });
                    }),
                })
                .then((res) => {
                    commit('setLogs', { logs: res.data });
                })
                .finally(() => {
                    state.isFetchingLogs = false;
                });
        },
        runUpdatingLogs ({ state, commit, dispatch }) {
            state.isFetchingLogs = true;

            dispatch('fetchLogs');

            let timer = setTimeout(function tick () {
                dispatch('fetchLogs');

                timer = setTimeout(tick, 1000);
                commit('setFetchingLogsTimer', { timer });
            }, 1000);

            commit('setFetchingLogsTimer', { timer });
        },
        clearLogsTimer ({ commit, state }) {
            commit('clearFetchingLogsTimer');

            if (state.cancelLogsRequest) {
                state.cancelLogsRequest('replaced');
            }
        },
        updateVersionData ({ commit }, { version }) {
            axios
                .get(API_VERSION_CHECK, { params: { v: encodeURI(version) } })
                .then((res) => {
                    commit('setVersionInfo', { versionInfo: res.data });
                });
        },

        fetchUserPermissions ({ commit, state }, { login }) {
            state.isFetchingPermissions = true;

            httpClient
                .get(getUserPermissionsUrl(login))
                .then((res) => {
                    commit('setCurrentUserPermissions', { permissions: res.data || [] });
                })
                .finally(() => {
                    state.isFetchingPermissions = false;
                });
        },
        updateUserPermissions ({ commit, state }, { permissions, login }) {
            state.isAddingPermission = true;

            httpClient
                .post(getUserPermissionsUrl(login), permissions, { timeout: 60000 })
                .then((res) => {
                    commit('setCurrentUserPermissions', { permissions: res.data });
                })
                .finally(() => {
                    state.isAddingPermission = false;
                });
        },
    },
};
