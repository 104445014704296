export const SERVER_ERR              = 'SERVER_ERR';
export const ACCESS_DENIED_ERR       = 'ACCESS_DENIED_ERR';
export const SERVER_UNKNOWN_ERR      = 'SERVER_UNKNOWN_ERR';
export const SERVER_NOT_RESPONSE_ERR = 'SERVER_NOT_RESPONSE_ERR';
export const CREDENTIALS_ERR         = 'CREDENTIALS_ERR';
export const STREAM_SOURCE_ERR       = 'STREAM_SOURCE_ERR';
export const STREAM_NAME_ERR         = 'STREAM_NAME_ERR';
export const ARCHIVE_DEPTH_ERR       = 'ARCHIVE_DEPTH_ERR';

export const SERVER_ERROR_CODES = {
    AlreadyUsed                          : 'AlreadyUsed',
    AuthorizationFailed                  : 'AuthorizationFailed',
    EnterpriseOnly                       : 'EnterpriseOnly',
    Forbidden                            : 'Forbidden',
    InternalServerError                  : 'InternalServerError',
    InvalidOldPassword                   : 'InvalidOldPassword',
    NotFound                             : 'NotFound',
    OldPasswordAndNewPasswordCannotBeSame: 'OldPasswordAndNewPasswordCannotBeSame',
    PasswordIsNotComplexEnough           : 'PasswordIsNotComplexEnough',
    RequestDecodeError                   : 'RequestDecodeError',
    ResponseEncodeError                  : 'ResponseEncodeError',
    TimeRangeIsTooLong                   : 'TimeRangeIsTooLong',
    Unavailable                          : 'Unavailable',
    ValidationError                      : 'ValidationError',
    ProbeError                           : 'ProbeError',
    LicenseExpired                       : 'LicenseExpired',
    QuotaReached                         : 'QuotaReached',
    IncorrectLicense                     : 'IncorrectLicense',

    EHOSTUNREACH     : 'EHOSTUNREACH',
    HTTP_NOT_FOUND   : 'HTTP_NOT_FOUND',
    HTTP_UNAUTHORIZED: 'HTTP_UNAUTHORIZED',
};
