import pages from './pages';

export default function pageNames ($gettext = (text) => text) {
    return {
        [pages.ADD_STREAM]       : $gettext('Add stream'),
        [pages.ADMIN]            : $gettext('Administration'),
        [pages.ADMIN_USERS]      : $gettext('Users'),
        [pages.ADMIN_CONFIG]     : $gettext('Configuration'),
        [pages.ADMIN_STATS]      : $gettext('Statistics'),
        [pages.ALL_STREAMS]      : $gettext('Streams'),
        [pages.AUTH]             : $gettext('Authorization'),
        [pages.BOOKMARKS]        : $gettext('Bookmarks'),
        [pages.HELP]             : $gettext('Help'),
        [pages.INIT]             : $gettext('Server initialization'),
        [pages.NOT_FOUND]        : $gettext('Page not found'),
        [pages.PROFILE]          : $gettext('My profile'),
        [pages.PROFILE_TOKEN]    : $gettext('My tokens'),
        [pages.PROFILE_INFO]     : $gettext('My profile'),
        [pages.PROFILE_EDIT]     : $gettext('My profile'),
        [pages.STREAM]           : $gettext('Stream'),
        [pages.STREAM_PERMISSION]: $gettext('Stream permissions'),
        [pages.STREAM_SETTING]   : $gettext('Stream settings'),
        [pages.STREAM_INFO]      : $gettext('Stream info'),
        [pages.SETTINGS]         : $gettext('Settings'),
        [pages.USER]             : $gettext('User profile'),
        [pages.USER_INFO]        : $gettext('User profile'),
        [pages.USER_EDIT]        : $gettext('Edit user'),
        [pages.USER_PERMISSION]  : $gettext('User permission'),
        [pages.USER_ADD]         : $gettext('Add user'),
        [pages.LICENSE]          : $gettext('License'),
        [pages.SERVER_DEBUG]     : $gettext('Server debug'),
        [pages.STREAM_DEBUG]     : $gettext('Stream debug'),
        [pages.CREATE_MOSAIC]    : $gettext('Create mosaic'),
        [pages.WATCH_MOSAIC]     : $gettext('Mosaic'),
        [pages.ALL_MOSAICS]      : $gettext('Mosaics'),
        [pages.EDIT_MOSAIC]      : $gettext('Edit mosaic'),
    };
}
