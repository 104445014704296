/* eslint-disable import/no-cycle */

import Vue                        from 'vue';
import Vuex                       from 'vuex';
import dayjs                      from 'dayjs';
import router                     from '@/router';
import httpClient                 from '@/config/httpClient';
import PAGES                      from '@/constants/pages';
import {
    STREAM_STATUS,
    VIEW_MODES_KEYS,
    GROUP_TYPES_KEYS,
    SORT_TYPES_KEYS,
    PANEL_SIZE,
}                                 from '@/constants/main';
import {
    GETTING_STREAMS_URL,
    LOGIN_URL,
    SELF_URL,
    UNLOCK_URL,
}                                 from '@/constants/api';
import {
    CREDENTIALS_ERR,
}                                 from '@/constants/errorTypes';
import {
    deleteCookie,
    getCookie,
    setCookie,
}                                 from '@/extends/lib';
import {
    isMediaAtLeast720,
    isMobile,
}                                 from '@/extends/browserEnv';
import { destinationPageStorage } from '@/extends/storageServices';
import {
    getGroupedStreams,
    saveToLocalStorage,
}                                 from './utilities';
import MUTATIONS                  from './mutations';
import player                     from './player/player';
import profile                    from './profile';
import admin                      from './admin';
import streamStore                from './stream';
import license                    from './license';
import mosaic                     from './mosaic';

Vue.use(Vuex);
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
export default new Vuex.Store({
    modules  : {
        admin,
        player,
        profile,
        streamStore,
        license,
        mosaic,
    },
    state    : {
        version: null,
        config : null,

        // Auth
        isAuthLoading  : false,
        isAdminCreating: false,

        // for LocalStorage
        isFixContent: true,
        isOpenAside : false,

        // StreamsPage
        pageNumberOfStreams    : 1,
        viewModeKey            : VIEW_MODES_KEYS.GRID,
        groupType              : GROUP_TYPES_KEYS.NO_GROUP,
        sortType               : SORT_TYPES_KEYS.DATE, // for LocalStorage
        isFetchingStreams      : true,
        isCheckingMode         : false,
        isShownAvailableStreams: false,
        searchingValues        : [],
        streams                : [],

        // GENERAL для всех страниц
        headerHeight        : PANEL_SIZE,
        isNotFoundPage      : false,
        errors              : {},
        notice              : {},
        healthWarnings      : [],
        isMediaAtLeast720   : isMediaAtLeast720(),
        isCardMode          : isMobile && !isMediaAtLeast720,
        isShowServerSwitcher: false,
    },
    mutations: {
        // Auth Page
        setVersion (state, { version }) {
            state.version = version;
        },
        setHealthWarnings (state, { healthWarnings }) {
            state.healthWarnings = healthWarnings;
        },
        [MUTATIONS.SET_AUTH_STATE.name] (state, { value }) {
            state.isAuthLoading = value;
        },

        // StreamsPage
        [MUTATIONS.SET_STREAMS.name] (state, payload) {
            state.streams = [...payload.streams];
        },
        [MUTATIONS.SET_GROUP_TYPE.name] (state, payload) {
            state[MUTATIONS.SET_GROUP_TYPE.property] = payload;
        },
        [MUTATIONS.SET_SEARCHING_VALUES.name] (state, payload) {
            state[MUTATIONS.SET_SEARCHING_VALUES.property] = [...payload];
        },
        [MUTATIONS.SET_VIEW_MODE_KEY.name] (state, payload) {
            state[MUTATIONS.SET_VIEW_MODE_KEY.property] = payload;
        },
        [MUTATIONS.SET_ASIDE_STATE.name] (state, payload) {
            state[MUTATIONS.SET_ASIDE_STATE.property] = payload;
        },
        [MUTATIONS.SET_SHOWING_AVAILABLE_STATE.name] (state, payload) {
            state[MUTATIONS.SET_SHOWING_AVAILABLE_STATE.property] = payload;
        },
        [MUTATIONS.SET_STREAMS_STATUSES.name] (state, payload) {
            state.streams = [...payload.streams];
        },

        // General
        [MUTATIONS.SET_FOUNDING_PAGE_STATE.name] (state) {
            state.isNotFoundPage = !state.isNotFoundPage;
        },
        [MUTATIONS.SET_ERROR.name] (state, { name, error }) {
            state.errors[name] = error;
            state.errors       = { ...state.errors };
        },
        [MUTATIONS.DELETE_ERROR.name] (state, { name }) {
            if (state.errors[name]) {
                delete state.errors[name];
            }
            state.errors = { ...state.errors };
        },
        [MUTATIONS.SET_CREATING_ADMIN_STATE.name] (state, { value }) {
            state.isAdminCreating = value;
        },
        setNotice (state, { name, message }) {
            state.notice = { name, message };
        },
        deleteNotice (state, { name }) {
            state.notice = null;
        },
        setHeaderHeight (state, { headerHeight }) {
            state.headerHeight = headerHeight;
        },
        setIsMediaAtLeast720 (state) {
            state.isMediaAtLeast720 = isMediaAtLeast720();
        },
        setIsShowServerSwitcher (state, { isShowServerSwitcher }) {
            state.isShowServerSwitcher = isShowServerSwitcher;
        },
    },
    getters  : {
        isMobile: () => isMobile,
        /* eslint-disable-next-line */
        availableStreams: (state) => {
            return state.streams.filter((stream) => stream.status === STREAM_STATUS.PENDING
                || stream.status === STREAM_STATUS.RUNNING);
        },
        searchedStreams : (state, getters) => {
            const uniqueStreamsIds = {};
            let allSearchedStreams = [];
            let streams            = state.isShownAvailableStreams ? getters.availableStreams : state.streams;

            function getValuesForSearching (stream) {
                let valuesForSearching = [stream.name, stream.streamSource];

                if (stream.tags && stream.tags.length > 0) {
                    valuesForSearching = [...valuesForSearching, ...stream.tags];
                }

                return valuesForSearching;
            }

            if (state.searchingValues.length > 0) {
                state.searchingValues.forEach((searchingValue) => {
                    streams.forEach((stream) => {
                        uniqueStreamsIds[stream.uid] = false;

                        const valuesForSearching = getValuesForSearching(stream);

                        if (stream.location && stream.location.name) {
                            valuesForSearching.push(stream.location.name);
                        }

                        valuesForSearching.forEach((item) => {
                            if (item
                                && item.toLowerCase().indexOf(searchingValue.toLowerCase()) !== -1
                                && uniqueStreamsIds[stream.uid] !== true) {
                                allSearchedStreams.push(stream);
                                uniqueStreamsIds[stream.uid] = true;
                            }
                        });
                    });
                    streams            = [...allSearchedStreams];
                    allSearchedStreams = [];
                });
            }
            return streams;
        },
        groupedStreams  : (state, getters) => {
            switch (state.groupType) {
                case GROUP_TYPES_KEYS.TAG:
                    return getters.groupedByTags;
                default:
                    return {
                        NO_GROUP: {
                            name   : 'All',
                            streams: getters.searchedStreams,
                        },
                    };
            }
        },
        groupedByTags   : (state, getters) => getGroupedStreams(getters.searchedStreams, GROUP_TYPES_KEYS.TAG),
        // AUTOCOMPLETE
        autocomplete: (state) => {
            const autocomplete = {
                names    : [],
                tags     : [],
                markers  : [],
                locations: [],
            };

            const markers   = {};
            const tags      = {};
            const locations = {};

            state.streams.forEach((stream) => {
                autocomplete.names.push({ value: stream.name });

                if (stream.meta && stream.meta.marker) {
                    markers[stream.meta.marker] = true;
                }

                if (stream.tags && stream.tags.length > 0) {
                    stream.tags.forEach((tag) => {
                        tags[tag] = true;
                    });
                }

                if (stream.location && stream.location.name && stream.location.name.length > 0) {
                    locations[stream.location.name] = true;
                }
            });

            Object.keys(markers).forEach((key) => {
                autocomplete.markers.push({ value: key });
            });
            Object.keys(tags).forEach((key) => {
                autocomplete.tags.push({ value: key });
            });
            Object.keys(locations).forEach((key) => {
                autocomplete.locations.push({ value: key });
            });

            return autocomplete;
        },
    },
    actions  : {
        createAdmin ({ commit, dispatch }, { settings }) {
            commit(MUTATIONS.SET_CREATING_ADMIN_STATE.name, { value: true });

            httpClient
                .post(UNLOCK_URL, settings)
                .then((res) => {
                    commit(MUTATIONS.DELETE_ERROR.name, { name: CREDENTIALS_ERR });
                    dispatch('saveToken', { token: res.data.token });
                    router.replace({ name: PAGES.ALL_STREAMS });
                })
                .finally(() => {
                    commit(MUTATIONS.SET_CREATING_ADMIN_STATE.name, { value: false });
                });
        },
        // Auth Page
        auth ({ commit, dispatch }, { credentials }) {
            commit(MUTATIONS.SET_AUTH_STATE.name, { value: true });

            httpClient
                .post(LOGIN_URL, credentials)
                .then((res) => {
                    commit(MUTATIONS.DELETE_ERROR.name, { name: CREDENTIALS_ERR });
                    dispatch('saveToken', { token: res.data.token });

                    const redirectPage = res.data.mustChangePassword ? PAGES.PROFILE_EDIT : PAGES.ALL_STREAMS;

                    router.replace(destinationPageStorage.getItem() || {
                        name  : redirectPage,
                        params: { mustChangePassword: res.data.mustChangePassword },
                    });
                    destinationPageStorage.removeItem();
                })
                .finally(() => {
                    commit(MUTATIONS.SET_AUTH_STATE.name, { value: false });
                });
        },
        saveToken ({ commit }, { token }) {
            const expires = new Date(dayjs(new Date()).add(1, 'month').valueOf());

            deleteCookie('token');
            setCookie('token', token, {
                expires,
                samesite: 'strict',
            });
        },
        logout ({ dispatch }) {
            deleteCookie('token');
            dispatch('profile/clearUser');
            destinationPageStorage.removeItem();
            router.replace({ name: PAGES.AUTH });
        },
        hasPermission () {
            return !!getCookie('token');
        },
        async checkPermission ({ dispatch, commit }) {
            return httpClient
                .get(SELF_URL)
                .then((res) => {
                    dispatch('updateVersion', { version: res.data.version });

                    if (res.data.warnings) {
                        commit('setHealthWarnings', { healthWarnings: res.data.warnings });
                    }
                    return { status: true };
                })
                .catch((error) => ({ statusCode: error.response.status, status: false }));
        },
        fetchHealth ({ commit, state, dispatch }) {
            if (state.version) {
                return;
            }
            httpClient
                .get(SELF_URL)
                .then((res) => {
                    dispatch('updateVersion', { version: res.data.version });

                    if (res.data.warnings) {
                        commit('setHealthWarnings', { healthWarnings: res.data.warnings });
                    }
                });
        },
        updateVersion ({ commit, state, dispatch }, { version }) {
            commit('setVersion', { version });
        },

        // StreamsPage
        fetchStreams ({ commit, state }) {
            state.isFetchingStreams = true;
            httpClient
                .get(GETTING_STREAMS_URL, {
                    params: {
                        page           : state.pageNumberOfStreams,
                        groupType      : state.groupType,
                        sortType       : state.sortType,
                        onlyAvailable  : state.isShownAvailableStreams,
                        searchingValues: state.searchingValues,
                    },
                })
                .then((res) => {
                    commit(MUTATIONS.SET_STREAMS.name, { streams: res.data });
                    state.isFetchingStreams = false;
                });
        },
        fetchStreamsStatuses ({ commit, state }) {
            httpClient
                .get(GETTING_STREAMS_URL, {
                    params: {
                        page           : state.pageNumberOfStreams,
                        groupType      : state.groupType,
                        sortType       : state.sortType,
                        isOnlyAvailable: state.isShownAvailableStreams,
                        searchingValues: state.searchingValues,
                    },
                })
                .then((res) => {
                    commit(MUTATIONS.SET_STREAMS_STATUSES.name, { streams: res.data });
                });
        },
        updateStreams ({ commit, state }, payload) {
            commit(MUTATIONS.SET_STREAMS.name, { streams: payload });
            state.isFetchingStreams = false;
        },
        updateStreamsStatuses ({ commit, state, dispatch }, payload) {
            dispatch('fetchStreamsStatuses', payload);
        },
        groupStreams ({ commit }, payload) {
            commit(MUTATIONS.SET_GROUP_TYPE.name, payload);
            saveToLocalStorage({
                key  : MUTATIONS.SET_GROUP_TYPE.property,
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        searchStreams ({ commit }, payload) {
            commit(MUTATIONS.SET_SEARCHING_VALUES.name, payload);
            saveToLocalStorage({
                key  : MUTATIONS.SET_SEARCHING_VALUES.property,
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        changeViewMode ({ commit, dispatch }, payload) {
            commit(MUTATIONS.SET_VIEW_MODE_KEY.name, payload);
            saveToLocalStorage({
                key  : MUTATIONS.SET_VIEW_MODE_KEY.property,
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        changeAsideState ({ commit }, payload) {
            commit(MUTATIONS.SET_ASIDE_STATE.name, payload);
            saveToLocalStorage({
                key  : MUTATIONS.SET_ASIDE_STATE.property,
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },
        toggleShowingAvailable ({ commit }, payload) {
            commit(MUTATIONS.SET_SHOWING_AVAILABLE_STATE.name, payload);
            saveToLocalStorage({
                key  : MUTATIONS.SET_SHOWING_AVAILABLE_STATE.property,
                value: payload,
                page : PAGES.ALL_STREAMS,
            });
        },

        toggleFoundingPageState ({ commit }) {
            commit(MUTATIONS.SET_FOUNDING_PAGE_STATE.name);
        },
        getStreamsPageSettingFromLocalStorage ({ commit }) {
            const streamsPageSettings = JSON.parse(localStorage.getItem(PAGES.ALL_STREAMS));

            if (streamsPageSettings) {
                [
                    MUTATIONS.SET_SEARCHING_VALUES,
                    MUTATIONS.SET_VIEW_MODE_KEY,
                    MUTATIONS.SET_ASIDE_STATE,
                    MUTATIONS.SET_SHOWING_AVAILABLE_STATE,
                    MUTATIONS.SET_GROUP_TYPE,
                ].forEach((item) => {
                    if (streamsPageSettings[item.property]) {
                        commit(item.name, streamsPageSettings[item.property]);
                    }
                });
            }
        },
        setError ({ commit, state }, { errorMsg, errorName }) {
            commit(MUTATIONS.SET_ERROR.name, { name: errorName, error: errorMsg });
        },
        deleteError ({ commit }, { errorName }) {
            commit(MUTATIONS.DELETE_ERROR.name, { name: errorName });
        },
        setNotice ({ commit, state, dispatch }, { name, message }) {
            commit('setNotice', { name, message });
        },
        deleteNotice ({ commit }, { name }) {
            commit('deleteNotice', { name });
        },
        resizeApp ({ commit }) {
            commit('setIsMediaAtLeast720');
        },
    },
});
