/* eslint-disable import/no-cycle */
import httpClient      from '@/config/httpClient';
import { LICENSE_URL } from '@/constants/api';

/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
export default {
    namespaced: true,
    state     : {
        license          : null,
        isFetchingLicense: false,
    },
    mutations : {
        setLicense (state, { license }) {
            state.license = license;
        },
    },
    actions   : {
        fetchLicense ({ commit, state }) {
            state.isFetchingLicense = true;

            httpClient
                .get(LICENSE_URL)
                .then((res) => {
                    commit('setLicense', { license: res.data });
                })
                .finally(() => {
                    state.isFetchingLicense = false;
                });
        },
        async putLicense ({ state, commit }, { license }) {
            state.isSavingLicense = true;

            await httpClient
                .put(LICENSE_URL, { text: license })
                .then((res) => {
                    commit('setLicense', { license: res.data });
                })
                .finally(() => {
                    state.isSavingLicense = false;
                });
        },
    },
};
