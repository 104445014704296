const BASE_STREAM_URL            = '/v1/stream';
export const ADDING_STREAM_URL   = BASE_STREAM_URL;
export const UPDATING_STREAM_URL = BASE_STREAM_URL;
export const GETTING_STREAM_URL  = BASE_STREAM_URL;
export const DELETE_STREAM_URL   = BASE_STREAM_URL;
export const PAUSE_STREAM_URL    = `${BASE_STREAM_URL}/pause`;
export const RESUME_STREAM_URL   = `${BASE_STREAM_URL}/resume`;
export const PROBE_STREAM_URL    = `${BASE_STREAM_URL}/probe`;
export const PREVIEW_STREAM_URL  = `${BASE_STREAM_URL}/preview`;

export const SYSTEM_SETTINGS_URL = '/v1/system/internal/ui/settings';
export const SYSTEM_SEARCH       = '/v1/system/internal/ui/search';
export const UNLOCK_URL          = '/v1/system/unlock';
export const SELF_URL            = '/v1/system/health';
export const LICENSE_URL         = '/v1/system/license';
export const SERVER_DEBUG_URL    = '/v1/system/logs';

export const LOGIN_URL           = '/v1/auth';
export const GETTING_STREAMS_URL = '/v1/streams';
export const USER_URL            = '/v1/user';
export const USERS_URL           = '/v1/users';
export const PROFILE_TOKENS_URL  = '/v1/tokens';
export const PROFILE_TOKEN_URL   = '/v1/token';
export const SYSTEM_STATS_URL    = '/v1/system/stats';
export const STATS_URL           = '/v1/admin/stats';
export const CONFIG_URL          = '/v1/admin/configuration';
export const ANNOTATIONS_URL     = '/v1/annotations';
export const MOSAIC_URL          = '/v1/mosaic';
export const ALL_MOSAICS_URL     = '/v1/mosaics';

export const API_VERSION_CHECK = 'https://api.yucca.app/v1/version-check';

export function getArchiveUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/index.m3u8`;
}

export function getRangesUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/ranges`;
}

export function getArchiveStatsUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/stats`;
}

export function getAvailableDaysUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/available-days`;
}

export function getDownloadArchiveUrl ({ streamUid }) {
    return `${BASE_STREAM_URL}/${streamUid}/archive/download`;
}

export function getStreamPermissionsUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/permissions`;
}

export function getStreamLogsUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/logs`;
}

export function getBlockUserUrl (login) {
    return `${USER_URL}/${login}/block`;
}

export function getUserPermissionsUrl (login) {
    return `${USER_URL}/${login}/permissions`;
}

export function getUnblockUserUrl (login) {
    return `${USER_URL}/${login}/unblock`;
}

export function getStreamShareUrl (streamUid) {
    return `${BASE_STREAM_URL}/${streamUid}/share`;
}

export function getDeleteToken (tokenId) {
    return `${PROFILE_TOKEN_URL}/${tokenId}`;
}

export function getStreamInfoUrl (streamUid, isLiveOnDemand = false) {
    return isLiveOnDemand
        ? `${GETTING_STREAM_URL}/${streamUid}?extend_lease=true`
        : `${GETTING_STREAM_URL}/${streamUid}`;
}
