import {
    EN,
    RU,
    UK,
}                   from '@/constants/main';
import translations from './translations';

export default function gettextOptions (lang) {
    return {
        translations,
        availableLanguages: {
            [EN]: 'English',
            [RU]: 'Русский',
            [UK]: 'Українська',
        },
        defaultLanguage   : lang || EN,
        silent            : true,
    };
}
