import {
    LANG_MAP,
    EN,
} from '@/constants/main';
import {
    DOCS_LINK,
    BASE_LANDING_URL,
} from '@/constants/links';

class YuccaBootConfig {
    constructor (
        {
            isEnterprise,
            isLocked,
            prober,
            revision,
            version,
            appTitle,
            appLogo,
            defaultLanguage,
            archiveDownloadMaxSeconds,
            playlistMaxSeconds,
            rangesMaxSeconds,
            isLicenseExpired,
            archiveMaxDepthHours,
            smtpServerEnabled,
            smtpServerHost,
            smtpServerProtected,
            smtpServerPort,
            documentation,
            site,
            features,
        },
        {
            language,
        },
    ) {
        this.isEnterprise         = isEnterprise || false;
        this.isLocked             = isLocked || false;
        this.prober               = prober || false;
        this.revision             = revision || '';
        this.version              = version || '';
        this.appTitle             = appTitle || 'Yucca';
        this.appLogo              = appLogo || '';
        this.defaultLanguage      = LANG_MAP[defaultLanguage] || EN;
        this.language             = LANG_MAP[language] || this.defaultLanguage;
        this.isLicenseExpired     = isLicenseExpired || false;
        this.archiveMaxDepthHours = archiveMaxDepthHours || -1;
        this.smtpServerEnabled    = smtpServerEnabled || true;
        this.smtpServerHost       = smtpServerHost || '';
        this.smtpServerPort       = smtpServerPort || '';
        this.smtpServerProtected  = smtpServerProtected || false;
        this.documentation        = documentation || DOCS_LINK;
        this.site                 = site || BASE_LANDING_URL;
        this.features             = features || {};

        this.archiveDownloadMaxSeconds = archiveDownloadMaxSeconds || 3600;
        this.playlistMaxSeconds        = playlistMaxSeconds || 604800;
        this.rangesMaxSeconds          = rangesMaxSeconds || 10800;
    }
}

const yccBootConfig = window.YUCCA_BOOT_DATA
    ? new YuccaBootConfig(window.YUCCA_BOOT_DATA.settings, window.YUCCA_BOOT_DATA.user)
    : null;

export {
    YuccaBootConfig,
    yccBootConfig,
};
