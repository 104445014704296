import translations from '../assets/lang/json/translations.json';

const en = {};
const ru = {
    ...translations.ru,
};
const uk = {
    ...translations.uk,
};

export default {
    en,
    ru,
    uk,
};
