export default {
    SET_SEARCHING_VALUES       : { name: 'setSearchingValues', property: 'searchingValues' },
    SET_VIEW_MODE_KEY          : { name: 'setViewModeKey', property: 'viewModeKey' },
    SET_ASIDE_STATE            : { name: 'setAsideState', property: 'isOpenAside' },
    SET_SHOWING_AVAILABLE_STATE: { name: 'setShowingAvailableState', property: 'isShownAvailableStreams' },
    SET_GROUP_TYPE             : { name: 'setGroupType', property: 'groupType' },
    SET_AUTH_STATE             : { name: 'setAuthState', property: 'isAuthLoading' },
    SET_STREAMS                : { name: 'setStreams', property: 'streams' },
    SET_STREAMS_STATUSES       : { name: 'setStreamsStatuses', property: 'streams' },
    SET_STREAM_STATUS          : { name: 'setStreamStatus', property: '' },
    SET_STREAM                 : { name: 'setStream', property: 'currentStream' },
    DELETE_CURRENT_STREAM      : { name: 'deleteCurrentStream', property: 'currentStream' },
    SET_DELETING_STREAM_STATE  : { name: 'setDeletingStreamState', property: 'deletingStateMap' },
    SET_CHANGING_STREAM_STATE  : { name: 'setChangingStreamState', property: 'changingStateMap' },
    SET_FOUNDING_PAGE_STATE    : { name: 'setFoundingPageState', property: 'isNotFoundPage' },
    SET_ERROR                  : { name: 'setError', property: 'errors' },
    DELETE_ERROR               : { name: 'deleteError', property: 'errors' },
    SET_STATUSES_TIMER         : { name: 'setFetchingStatusTimer', property: 'fetchingStatusTimer' },
    SET_CREATING_ADMIN_STATE   : { name: 'SET_CREATING_ADMIN_STATE', property: 'isAdminCreating' },
};
